import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import './admin.css'
import HoverInfo from '../common/hoverInfo';
import { checkGroups } from '../helpers/checkGroupName';

class ManageGroups extends Component {
  constructor(props) {
    super();
    this.state = { admin: true, groups: null, locations: [], createLocations: [], group: {}, show: false, checked: false }
  }

  componentDidMount() {
    //check for correct groups (creates flash error if group name has been changed)
    let check = checkGroups();
    checkGroups().then((response) => {
      console.log(response)
      if (response.error) { this.setState(check) }
    });
    this.populateGroupSelect();
  }

  //add a new input field for locations(edit)
  addNewLocation = () => {
    this.setState(prevState => ({ locations: prevState.locations.concat('') }))
  }

  //add a new input field for locations(create)
  addNewCreateLocation = () => {
    this.setState(prevState => ({ createLocations: prevState.createLocations.concat('') }))
  }

  //form to create a new group
  createGroupForm = () => {
    let groupNameLabel = [];
    groupNameLabel.push(<p key="label" style={{ display: 'inline-block' }}>Group Name</p>);
    groupNameLabel.push(<HoverInfo key="info" message="You can name your group however you like but we recommend matching the Grouper Group's name." />);
    
    let groupPathLabel = [];
    groupPathLabel.push(<p key="label" style={{ display: 'inline-block' }}>Group Path</p>);
    groupPathLabel.push(<HoverInfo key="info" message="Input the Grouper Path from the Grouper app." />);
    
    let teamsUrlLabel = [];
    teamsUrlLabel.push(<p key="label" style={{ display: 'inline-block' }}>Teams Webhook URL</p>);
    teamsUrlLabel.push(<HoverInfo key="info" message="The Teams Webhook URL allows you to send a Teams notification for clock-in and clock-outs." />);

    return <form method="get" onSubmit={this.submitCreate} >
      <div className='tooltip-form'>
        <TextField name="name" label={groupNameLabel} />
      </div>

      <div className='tooltip-form'>
        <TextField name="grouperPath" label={groupPathLabel} />
      </div>

      <div className='tooltip-form'>
        <TextField name="teamsUrl" label={teamsUrlLabel} />
      </div>


      <div className='tooltip-form'>
        <div className="field" style={{ display: 'inline-block' }}>
          <div className="control">
            <label className="checkbox">
              <input type="checkbox" name="requireCover" />
              Require shift cover
            </label>
          </div>
        </div>
        <HoverInfo message="When selected, employees who offer up their shifts will still be responsible for that shift until it is either released by a supervisor or covered by another employee." />
      </div>

      <TextField name="supervisor" label="Add Supervisor Netid" />

      <div className="field">
        <label className="label">Locations</label>
        <div className='location-buttons'>
          <FontAwesomeIcon className="clickable" icon={faPlusCircle} onClick={this.addNewCreateLocation} />
          <FontAwesomeIcon className="clickable" icon={faMinusCircle} onClick={this.removeCreateLocationField} />
          <span>Add/Remove a work location</span>
        </div>
        <div className="control">
          <LocationFields locations={this.state.createLocations} />
        </div>
      </div>

      <div className="field is-grouped">
        <FormBtn className="button is-info" type="submit" value="Create" />
      </div>
    </form>
  }

  //form to edit work group
  editGroupForm = () => {
    let nameLabel = [];
    nameLabel.push(<p key="label" style={{ display: 'inline-block' }}>Group Name</p>);
    nameLabel.push(<HoverInfo key="info" message="Note that upon changing a group name, everyone in that group must log out, then log back in." />);
    if (this.state.group) {
      return <form method="get" onSubmit={this.submitEdit} className={this.state.show ? '' : 'hide-form'}>
        <div>
        <div className='tooltip-form'>
          <TextField name="name" label={nameLabel} value={this.state.group.name} />
          </div><div className='tooltip-form'>
          <TextField name="grouperPath" label="Grouper Path" value={this.state.group.path} />
          </div><div className='tooltip-form'>
          <TextField name="teamsUrl" label="Teams Webhook URL" value={this.state.group.teamsUrl} />
          </div><div className='tooltip-form'>
          <div className="field" style={{ display: 'inline-block' }}>
            <div className="control">
              <label className="checkbox">
                <input type="checkbox" name="requireCover" defaultChecked={this.state.checked} onChange={(event) => this.setState({ checked: event.target.checked })} />
                Require shift cover
              </label>
            </div>
          </div>
          <HoverInfo message="When selected, employees who offer up their shifts will still be responsible for that shift until it is either released by a supervisor or covered by another employee." />
</div>
          <div className="field">
            <label className="label">Supervisors</label>
            <div className="control">
              {this.state.group.supervisors ? this.state.group.supervisors.join(', ') : 'No supervisors'}
            </div>
          </div>

          <TextField name="supervisor" label="Add Supervisor Netid" />

          

            <div className="field">
        <label className="label">Locations</label>
        <div className='location-buttons'>
          <FontAwesomeIcon className="clickable" icon={faPlusCircle} onClick={this.addNewCreateLocation} />
          <FontAwesomeIcon className="clickable" icon={faMinusCircle} onClick={this.removeCreateLocationField} />
          <span>Add/Remove a work location</span>
        </div>

            <div className="control">
              <LocationFields locations={this.state.locations} />
            </div>
          </div>

          <div className="field is-grouped">
            <FormBtn className="button is-info" type="submit" value="Update" />
            <FormBtn className="button is-info" onClick={this.handleMembers} isDisabled={!this.state.group} value="Grouper Sync" />
            <FormBtn className="button is-info is-light" type="button" value="Cancel" onClick={() => this.setState({ show: false, group: {}, locations: [], createLocations: [] })} />
            <FormBtn className="button is-danger" onClick={this.handleDelete} isDisabled={!this.state.group} value="Delete" />
          </div>
        </div>
      </form>
    }
  }

  //format data to send to express
  formatPostBody = (event) => {
    let locations = [];

    //check for incomplete form
    if (!event.target.name.value) {
      this.setState({ error: "Group name empty", success: false });
      return null;
    }
    if (!event.target.locations) {
      this.setState({ error: ("No locations set for group: " + event.target.name.value), success: false });
      return null;
    }


    if (event.target.locations.length) {
      for (let i = 0; i < event.target.locations.length; i++) { locations.push(event.target.locations[i].value) }
    }
    else {
      locations.push(event.target.locations.value)
    }

    let body = {
      group:
      {
        name: event.target.name.value,
        grouperPath: event.target.grouperPath.value,
        locations: locations,
        teamsUrl: event.target.teamsUrl.value,
        requireCover: event.target.requireCover.checked
      },
      role: {
        netid: event.target.supervisor.value,
        role: "supervisor"
      }
    }
    return body
  }

  //delete a group
  handleDelete = () => {
    let self = this;
    axios.put("workGroups/deleteGroup", this.state.group)
      .then(response => {
        self.props.updateFlash({ success: true, error: false });
        self.setState({ newGroup: { name: '', path: '', teamsUrl: '', requireCover: null, supervisor: null }, group: null });
        self.populateGroupSelect();
      }).catch((error) => {
        console.log(error)
      });
  }

  //fetch user information from streamer
  handleMembers = (name, path) => {
    let self = this;
    let groupName;
    let groupPath;
    if (name && path) {
      groupName = name;
      groupPath = path;
    } else {
      groupName = self.state.group.name;
      groupPath = self.state.group.path;
    }
    axios.get("workGroups/fetchMembers/" + groupName + '/' + groupPath)
      .then(response => {
        self.props.updateFlash({ success: true, error: false });
      }).catch((error) => {
        console.log(error)
      });
  }

  //fetch user information from streamer
  populateGroupSelect = () => {
    axios.get("workGroups/fetchGroups").then(response => {
      let groups = response.data;
      if (groups) {
        let index = groups.findIndex(group => group.name === "adhoc")
        groups.splice(index, 1)
      }
      this.setState({ groups: response.data })
    }).catch((error) => {
      console.log(error)
    });
  }

  //delete the last location input field
  removeLocationField = () => {
    this.state.locations.pop()
    this.setState(prevState => ({
      myArray: [prevState.locations]
    }))
  }


  //delete the last location input field
  removeCreateLocationField = () => {
    this.state.createLocations.pop()
    this.setState(prevState => ({
      myArray: [prevState.createLocations]
    }))
  }


  // set the active group to edit 
  setActiveGroup = (event) => {
    let value = event.target.value;
    let group;
    if (value === 'new') {
      this.setState({ newGroup: { name: '', path: '', teamsUrl: '', requireCover: null, supervisor: null }, group: null, locations: [] })
    } else if (value === 'open') {
      this.setState({ group: null, newGroup: null, locations: [] });
    } else {
      group = this.state.groups.find(group => group.name === value);
      axios.get("users/getSupervisors/" + value).then(response => {
        let supervisors = response.data.map(s => s.display_name);
        this.setState({
          group: { name: group.name, path: group.grouperPath, teamsUrl: group.teamsUrl, requireCover: group.requireCover, supervisors: supervisors },
          newGroup: { name: group.name, path: group.grouperPath },
          locations: group.locations,
          show: true,
          checked: group.requireCover
        });
      }).catch((error) => {
        console.log(error)
      });
    }
  }

  //send data to express to create group
  submitCreate = (event) => {
    event.preventDefault();
    const body = this.formatPostBody(event);
    console.log(body)
    const self = this;

    if (body) {
      axios.post("workGroups/create/", body.group)
        .then(response => {
          self.setSupervisorRole(body.role, body.group.name);
          self.handleMembers(body.group.name, body.group.grouperPath);
          self.props.updateFlash({ success: true, error: false });
          self.setState({ locations: [], createLocations: [] });
          self.populateGroupSelect();
        }).catch((error) => {
          console.log(error)
        });
    }
  }

  //set role for a person
  setSupervisorRole = (role, group) => {
    axios.put("users/updateSupervisorRole", { role: role, group: group })
      .then()
      .catch((error) => {
        console.log(error)
      });
  }

  //send data to express to edit group
  submitEdit = (event) => {
    event.preventDefault();
    // console.log(event)
    const body = this.formatPostBody(event)
    const self = this;

    if (body) {
      // console.log(this.state.group.name, " ->", body.group);
      if (this.state.group.name !== body.group.name) {
        // this.updateLocalStorage(this.state.group.name, body.group.name);
        this.setState({ warning: "Group name changed: You and all members of this group need to log out and log back in. Until starting a new session, you may lose some functionality/have trouble accessing all information associated with this group." });
      }
      axios.put("workGroups/update/" + this.state.group.name, body.group)
        .then(response => {
          self.setSupervisorRole(body.role, this.state.group.name);
          // self.handleMembers();

          self.props.updateFlash({ success: true, error: false });
          self.setState({ locations: [], createLocations: [], show: false, group: {} });
          self.populateGroupSelect();

          //check for correct groups (creates flash error if group name has been changed)
          let check = checkGroups();
          this.setState(check);
        }).catch((error) => {
          console.log(error)
        });
    }
  }

  render() {
    return <div className="columns">
      <div className="column is-half">
        <div className="card">
          <div className="card-content">
            <h3 className="label">Create a new group: </h3>
            {this.createGroupForm()}
          </div>
        </div>
      </div>

      <div className="column is-half">
        <div className="card">
          <div className="card-content">
            <h3 className="label">Select group to edit: </h3>
            <div className="select">
              <select name="group" onChange={this.setActiveGroup} defaultValue="none">
                <option hidden value="none"> -- select an option -- </option>
                <GroupDropDownOptions groups={this.state.groups} />
              </select>
            </div>
            {this.editGroupForm()}
          </div>

        </div>
      </div>
    </div>
  }
}

//////
// helper components
//////

//adds dropdown options from group edit select
function GroupDropDownOptions(props) {
  if (props.groups) {
    return props.groups.map((group, index) => <option key={group.name} value={group.name}>{group.name}</option>)
  } else {
    return null
  }
}

function FormBtn(props) {
  const type = props.type ? props.type : "button"
  return <div className="control">
    <button className={props.className} type={type} onClick={props.onClick} disabled={props.isDisabled}>{props.value}</button>
  </div>
}

//the input fields for group locations
function LocationFields(props) {
  if (!props.locations || props.locations.length === 0) { return null; }
  return props.locations.map((location, index) => <input key={location} className='input' type="text" name="locations" defaultValue={location} />)
}

//text fields
function TextField(props) {
  return <div className="field">
    <label className="label">{props.label}</label>
    <div className="control">
      <input key={props.value} className='input' type="text" name={props.name} defaultValue={props.value} />
    </div>
  </div>
}

export default ManageGroups;
