import React, { Component } from "react";
import DataTableComp from "./dataTableComp";
class Table extends Component {

    render() {
        if (!this.props.data || this.props.data.length < 1) {
            // return <h1 className="title is-5">No Data Yet</h1>
            return null
        } else {
            return (
                <div>
                    <DataTableComp columns={this.props.columns} data={this.props.data} columnDefs={this.props.columnDefs}/>
                </div>
            );
        }
    }
}
export default Table;