import React, { Component } from 'react';
import axios from 'axios';
import format from 'date-fns/format';

class DraftAvailability extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            name: "--Select--"
        }
    }

    componentDidMount() {
        this.availabilityTable();
        this.shiftsTable();
    }

    componentDidUpdate(prevProps) {
        let changedProps = prevProps.highlightedEmployee !== this.props.highlightedEmployee
        if (changedProps) {
            this.availabilityTable()
            this.shiftsTable();
        }
    }

    //shows the options
    openSelections = () => {
        this.setState({ open: !this.state.open })
    }

    closeSelections = () => {
        this.setState({ open: false })
    }

    //allows changes made to the customized select/options
    handleSelection = (event) => {
        const value = event.target.getAttribute('value');
        if (value !== 'Available' && value !== '--Select--') {
            let user = this.props.users.filter(obj => { return obj.netid === value })[0]
            this.props.changeEmployeeHandler("employee", user);
        } else {
            this.props.changeEmployeeHandler("employee", value)
        }
    }

    //populate dropdown for users
    mapUsers = () => {
        let options = []
        options.push(<p className={"custom-option"} key={"All Employees"} value={"--Select--"} onClick={this.handleSelection.bind(this)}>
            --Select--
        </p>)
        options.push(<p className={"custom-option"} key={"Available"} value={"Available"} onClick={this.handleSelection.bind(this)}>
            Available
        </p>)
        if (this.props.users) {
            for (let index in this.props.users) {
                let user = this.props.users[index];
                let count;
                if (this.props.shifts && this.props.shifts[user.netid]) {
                    count = "[ " + Math.round(this.props.shifts[user.netid] * 100) / 100 + " hr ]";
                }
                options.push(<p className={"custom-option"} key={user.netid} value={user.netid} onClick={this.handleSelection.bind(this)}>
                    {user.display_name || user.name} {count}
                </p>)
            }
        }
        return (options)
    }

    showEmployee = () => {
        return (
            <div className="select custom-select-wrapper" onClick={this.openSelections}>
                <div className={this.state.open ? "custom-select open" : "custom-select"}>
                    <p> {this.props.highlightedEmployee?.display_name || this.props.highlightedEmployee} </p>
                    <div className="custom-options">
                        {this.mapUsers()}
                    </div>
                </div>
            </div>
        )
    }

    showAvailability = (days, available) => {
        for (let day in days) {
            if (days[day] && days[day].length > 0) {
                let time = '';
                days[day].forEach((timeSlot) => {
                    if (timeSlot.prettyStart && timeSlot.prettyEnd) {
                        time += ` ${timeSlot.prettyStart} - ${timeSlot.prettyEnd}`;
                    }
                })
                available.push(<li key={day + time}> {day}: {time}</li>);
            }
        }
    }

    availabilityTable = () => {
        let available = [];
        if (this.props.highlightedEmployee) {
            axios.get('users/find/' + this.props.highlightedEmployee.netid)
                .then((response) => {
                    if (this.props.highlightedEmployee) {
                        this.setState({ preferredHours: this.props.highlightedEmployee.preferredHours });
                    }
                    if (response.data?.weekly_availability) {
                        available.push(<span key="preferred">Preferred Time:</span>);
                        this.showAvailability(response.data.weekly_availability, available);
                    }
                    if (response.data?.weekly_maybe) {
                        available.push(<span key="available">Available Time:</span>);
                        this.showAvailability(response.data.weekly_maybe, available);
                    }
                    if (available.length < 2) {
                        if (this.props.isUnavailability) {
                            available.push(<li key="None">No unavailability</li>);
                        } else {
                            available.push(<li key="None">No availability</li>);
                        }
                    }
                    this.setState({ availabilities: available });
                }).catch(function (err) {
                    console.log(err)
                })
        } else {
            if (this.props.isUnavailability) {
                available.push(<li key="None">No unavailability</li>);
            } else {
                available.push(<li key="None">No availability</li>);
            }

            this.setState({ availabilities: available });
        }
    }

    shiftsTable = async () => {
        let shifts = []
        let self = this
        if (this.props.highlightedEmployee) {
            const schedules = await this.getScheduleShifts(this.props.highlightedEmployee);
            for (const [scheduleName, scheduleShifts] of Object.entries(schedules)) {
                if (scheduleShifts.length > 0) {
                    shifts.push(<li key={scheduleName}>{scheduleName}</li>)
                    let index = 0
                    for (const shift of scheduleShifts) {
                        shifts.push(<li className="second-level" key={scheduleName + " Shift" + index}> {format(shift.start, 'MM/dd HH:mma')} - {format(shift.end, 'HH:mma')}</li>)
                        index++
                    }
                }
            }
            if (shifts.length === 0) {
                shifts.push(<li key="No shifts"> No Shifts</li>)
            }
            self.setState({ shifts: shifts })
        }
    }

    getScheduleShifts = async (employee) => {
        let self = this
        let scheduleShifts = {}
        let schedules = await self.getSchedules()
        for (const schedule of schedules) {
            let shifts = await self.getShifts(schedule, employee)
            let location = shifts[0]?.extendedProps?.location;
            scheduleShifts[`${schedule.scheduleName} - ${location}`] = shifts
        }
        return scheduleShifts
    }

    getTrueStartEnd = () => {
        let start = new Date(this.props.start_time * 1000);
        let trueStart = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0);
        let end = new Date(this.props.end_time);
        let trueEnd = new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1, 0, 0, 0);
        return [trueStart / 1000, trueEnd / 1000];
    }

    getSchedules = async () => {
        let [trueStart, trueEnd] = this.getTrueStartEnd();
        let schedules = []
        schedules = await axios.get(`schedule/find/user/${this.props.highlightedEmployee.netid}/${trueStart}/${trueEnd}`).then((response) => {
            return response.data
        }).catch(function (err) {
            console.log(err)
        })
        return schedules
    }

    getShifts = async (schedule, employee) => {
        let [trueStart, trueEnd] = this.getTrueStartEnd();
        let shifts = []
        shifts = await axios.get("shifts/find_time_schedule_fc/" + (schedule.scheduleId) + "/" + (trueStart) + "/" + (trueEnd) + "/" + employee?.netid)
            .then((shiftsResponse) => {
                return shiftsResponse.data;
            }).catch(function (err) {
                console.log(err)
            })
        return shifts
    }

    render() {
        let label = <label className="label">Availability</label>
        return (
            <form>
                <h2 className="title">Employee Schedule</h2>

                <div className="field">
                    <div className="control">
                        <label className="label">Employee</label>
                        {this.showEmployee()}
                    </div>
                </div>

                {this.props.highlightedEmployee?.netid ? <><div className="field">
                    <div className="control" id="preferred-container">
                        <label className="label" id="preferred-hours">Preferred Number of Hours</label>
                        {this.state.preferredHours > 0 ? (
                            <p>{this.state.preferredHours}</p>
                        ) : (
                            <p>Select a staffer first.</p>
                        )}
                    </div>
                </div>

                    <div className="field">
                        <div className="control">
                            {label}
                            {this.state.availabilities}
                        </div>
                    </div>

                    <div className="field">
                        <div className="control">
                            <label className="label">Shifts</label>
                            {/* {this.state.shifts} */}
                            {this.state.shifts ? (
                                <p>{this.state.shifts}</p>
                            ) : (
                                <p>Select a staffer first.</p>
                            )}
                        </div>
                    </div></> : ''}
            </form>
        );
    }
}

export default DraftAvailability;