import React from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import format from 'date-fns/format'
import axios from 'axios';
import addDays from 'date-fns/addDays'
import IncludeEmployeeModal from './includeEmployeesModal'
import ShiftsModal from './shiftsModal'



class DraftCalendar extends React.Component {

  constructor(props) {
    super();
    this.state = {
      autoGenBtn: "Auto Match Shifts",
      showModel: false,
      showIncludeEmployeeModal: false
    }
  }

  componentDidMount() {
    // Attach global keyup listener
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
    this.selectDayOnCalendar();
  }

  componentWillUnmount() {
    // Remove the listener on component unmount
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  isSubset = (subset, superset) => subset.every(item => superset.includes(item));

  // when a day is selected on calendar, select all events of the day
  selectDayOnCalendar = () => {
    const daysHeader = document.querySelector('.fc-scrollgrid-section-header');
    if (daysHeader) {
      // Add an event listener to the container
      daysHeader.addEventListener('click', (event) => {
        // Check if the clicked element is a day link
        const dayLink = event.target.closest('.fc-col-header-cell-cushion');
        if (dayLink) {
          // Get the parent column header to extract the date
          const parentCell = dayLink.closest('.fc-col-header-cell');
          const date = parentCell.getAttribute('data-date');
          if (date) {
            const calendarApi = this.props.calendarRef?.current?.getApi(); // Assuming you have the calendar instance
            const events = calendarApi.getEvents();
            // Filter events that match the clicked date
            const eventsForDay = events.filter(event => {
              const eventStart = event.start.toISOString().split('T')[0]; // Format as YYYY-MM-DD
              return eventStart === date;
            });
            const eventsIdForDay = eventsForDay.map(e => e.extendedProps.shiftId);

            // check if all events of a day already seleted
            let dayAreadySelected = this.isSubset(eventsIdForDay, this.props.selectedShiftIds);
            if (!this.state.multi) {
              this.props.removeAllEventHighlight();
              this.props.resetState();
            }

            // Optionally highlight the events
            eventsForDay.forEach(eventInfo => {
              let event = eventInfo.extendedProps;
              let selectedShiftIds = this.props.selectedShiftIds;
              let selectedShifts = this.props.selectedShifts;
              let findIndex = selectedShiftIds.indexOf(event.shiftId);
              if (dayAreadySelected) { // toggle selection if all events already selected
                selectedShiftIds.splice(findIndex, 1);
                selectedShifts.splice(findIndex, 1);
              } else if (findIndex === -1) { // add all events of a dayg
                selectedShiftIds.push(event.shiftId);
                selectedShifts.push(event);
              }
              this.props.setselectedShiftIds(selectedShiftIds, selectedShifts);
            });
          }
        }
      });
    }
  }

  copyWeek = () => {
    let self = this;
    const firstDayEpoch = self.props.calendarRef.current.getApi().getDate().getTime()
    axios.get(`schedule/copyWeek/${firstDayEpoch}/${this.props.data.scheduleId}`).then((response) => {
      self.props.setFlash(false, ["Copied!"]);
    }).catch(function (err) {
      self.props.setFlash(["Error"], false);
      console.log(err)
    });
  }

  pasteWeek = () => {
    let self = this;
    const firstDayEpoch = self.props.calendarRef.current.getApi().getDate().getTime()

    axios.get(`schedule/pasteWeek/${firstDayEpoch}/${this.props.data.scheduleId}`).then((response) => {
      self.props.getSchedule(this.props.data.scheduleId);
      self.props.resetState();
      self.props.setFlash(false, ["Paste!"]);
    }).catch(function (err) {
      console.log(err)
      // self.setFlash(true, ["Error"]);
    });
  }

  showModel = (type) => {
    this.setState({ showModel: type })
  }

  closeModal = () => {
    this.setState({ showModel: false })
  }

  showIncludeEmployeeModal = () => {
    this.setState({ showIncludeEmployeeModal: true })
  }

  closeIncludeEmployeeModal = () => {
    this.setState({ showIncludeEmployeeModal: false })
  }

  // when event is clicked, add to shift selections(requires confirmation)
  handleEventClick = async (eventInfo) => {
    let event = eventInfo.event.extendedProps;
    let selectedShiftIds = this.props.selectedShiftIds;
    const numIds = selectedShiftIds.length;
    let selectedShifts = this.props.selectedShifts;
    let findIndex = selectedShiftIds.indexOf(event.shiftId);
    if (findIndex !== -1) { // already in the list
      if (!this.state.multi) { // single select, remove current select
        await this.props.removeAllEventHighlight();
        this.props.resetState();
        selectedShiftIds = this.props.selectedShiftIds;
        selectedShifts = this.props.selectedShifts;
        if (numIds > 1) { // multi selected -> single select, keep this selection
          selectedShiftIds.push(event.shiftId);
          selectedShifts.push(event);
        }
      } else { // multi select, double click on a already selected shift
        selectedShiftIds.splice(findIndex, 1);
        selectedShifts.splice(findIndex, 1);
      }
    } else { // not in the list
      if (!this.state.multi) { // single select, remove current selection
        await this.props.removeAllEventHighlight();
        this.props.resetState();
        selectedShiftIds = this.props.selectedShiftIds;
        selectedShifts = this.props.selectedShifts;
      }
      selectedShiftIds.push(event.shiftId); // add this select
      selectedShifts.push(event);
    }
    this.props.setselectedShiftIds(selectedShiftIds, selectedShifts);
  }

  // when full calendar is updated (shift is added, updated, or removed), update the shifts in the Build component
  changeFullCalendar = (e) => {
    if (!e.event.extendedProps.temp && !e.event.extendedProps.selected) {
      // console.log(e);
      if (!this.props.checkError(e.event.start, e.event.end)) {
        let newObj = {} //full calendar sets the event as write only, so we create our own obj
        const newStart = e.event.start / 1000;
        const newEnd = e.event.end / 1000;
        let shiftInfo = e.event.extendedProps
        for (const [key, value] of Object.entries(shiftInfo)) {
          newObj[key] = value
        }
        newObj.start_time = newStart;
        newObj.end_time = newEnd;
        // console.log(newObj);
        this.props.checkAvailability(e, newObj);
      }
    }
  }


  renderButtons = () => {
    return (
      <div className="buttons is-grouped mt-3">
        <div className='tooltipped-button'>
          <input className="button is-info is-light" type='button' value={this.state.autoGenBtn} onClick={this.showIncludeEmployeeModal} />
          <span className="tooltiptext">Match Employee To Shifts</span>
        </div>
        <div className='tooltipped-button'>
          <input className="button is-primary is-light" type='button' value={this.state.selecting ? "Selecting..." : "Select By"} onClick={() => this.setState({ showModel: true, selecting: true })} />
          <span className="tooltiptext">Select Shifts By Name/Range</span>
        </div>
        <div className='tooltipped-button'>
          <input className="button is-info is-light" type='button' value="Publish Shifts" onClick={this.handlePublish} />
          <span className="tooltiptext">Publish Selected Shifts</span>
        </div>
        <div className='tooltipped-button'>
          <input className="button is-info is-light" type='button' value="Unpublish Shifts" onClick={this.handleUnPublish} />
          <span className="tooltiptext">Unpublish Selected Shifts</span>
        </div>
        <div className='tooltipped-button'>
          <input className="button is-danger is-light" type='button' value="Delete Shifts" onClick={this.handleDelete} />
          <span className="tooltiptext">Delete Selected Shifts</span>
        </div>
        <div className='tooltipped-button'>
          <input className="button is-info is-light" type='button' value="Copy Week" onClick={this.copyWeek} />
          <span className="tooltiptext">Copy All Shifts This Week</span>
        </div>
        <div className='tooltipped-button'>
          <input className="button is-info is-light" type='button' value="Paste Week" onClick={this.pasteWeek} />
          <span className="tooltiptext">Paste Copied Shifts to This Week</span>
        </div>
      </div>
    )
  }

  drawCalendar() {
    const self = this;
    const customButtons = {
      autoMatchBtn: {
        text: this.state.autoGenBtn,
        hint: "Match Employee To Shifts",
        click: this.showIncludeEmployeeModal
      },
      selectBy: {
        text: this.state.selecting ? "Selecting..." : "Select By",
        hint: "Select Shifts By Name/Range",
        click: () => this.setState({ showModel: true, selecting: true })
      },
      publishShifts: {
        text: "Publish Shifts",
        hint: "Publish Selected Shifts",
        click: this.handlePublish
      },
      unpublishShifts: {
        text: "Unpublish Shifts",
        hint: "Unpublish Selected Shifts",
        click: this.handleUnPublish
      },
      deleteShifts: {
        text: "Delete Shifts",
        hint: "Delete Selected Shifts",
        click: this.handleDelete
      },
      copyWeek: {
        text: "Copy Week",
        hint: "Copy All Shifts This Week",
        click: this.copyWeek
      },
      pasteWeek: {
        text: "Paste Week",
        hint: "Paste Copied Shifts to This Week",
        click: this.pasteWeek
      }
    }
    return (
      <div className="card">
        <div className="card-content">
          <FullCalendar
            ref={this.props.calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            customButtons={customButtons}
            headerToolbar={{ // calendar header with navigation options
              left: 'today prev,next',
              center: 'title',
              right: 'timeGridWeek,listWeek,dayGridDay'
            }}
            // footerToolbar={{ // calendar header with navigation options
            //   center: 'autoMatchBtn selectBy publishShifts unpublishShifts deleteShifts copyWeek pasteWeek'
            // }}
            loading={function (bool) {
              if (bool) {
                //  console.log('I am populating the calendar with events');
              }
              else {
                self.props.showFlash();
                // bind to all your events here
              }
            }}
            unselectAuto={false}
            initialView="timeGridWeek" // show week as initial calendar
            height={700} // TODO: reasonable responsive height
            showNonCurrentDates={false} // month calendar grays out days not in specified time period
            allDaySlot={false} // no all day events
            nowIndicator={true} // red line and arrow to indicate current time
            scrollTimeReset={false}
            scrollTime={'08:00:00'} // week/day view begins at 9 am
            editable={true} // allows shift modification w/ clicking and dragging
            selectable={true} // select time slot by clicking and dragging
            select={this.props.handleDateSelect} // create shift after selecting time slot
            eventClick={this.handleEventClick} // delete shift after clicking on it
            validRange={{ // user can only move around calendar in time range specified in form (from 'Generate a Schedule')
              start: this.props.calendarStartDate,
              end: this.props.calendarEndDate
            }}
            eventAdd={this.changeFullCalendar} // triggers when event is added to calendar: updates shifts in Build component
            eventChange={this.changeFullCalendar} // triggers when event is updated: updates shifts in Build component
            eventRemove={this.changeFullCalendar} // triggers when event is removed from calendar: updates shifts in Build component
            eventContent={this.renderEventContent.bind(this)}   // overrides fullcalendar's event display
            eventBorderColor={"whitesmoke"}
            initialEvents={this.getEventInfo} // (needs to be a function so we can use refetchEvents call to update)
          />
          {this.showLegend()}
          {this.renderButtons()}
        </div>
      </div >
    )
  }

  //get initial events
  getEventInfo = (info, successCallback, failureCallback) => {
    let self = this;
    if (self.props.data.shifts) {
      // console.log("info?")
      // console.log(info)
      // console.log("LOGGING SHIFTS");
      // console.log(self.props.data.shifts)
      self.props.data.shifts.forEach((shift, index) => {
        shift['start'] = shift['start_time'] * 1000;
        shift['end'] = shift['end_time'] * 1000;
      }
      )
      successCallback(self.props.data.shifts) //TODO format these cells :)
    } else {
      // console.log('no shifts');
      successCallback([]) //if no shifts, draw nothing
    }
  }

  // overrides fullcalendar's event display
  renderEventContent(eventInfo) {
    let divStyle, highlight;
    let employee = eventInfo.event.extendedProps.employee;
    let event = eventInfo.event.extendedProps;
    let name = employee ? (employee.display_name || employee.name) : "Available";
    const netid = employee ? employee.netid : "Available";
    const calendar = this.props.calendarRef?.current?.getApi();
    const currentView = calendar?.getCurrentData().currentViewType;
    if (this.props.highlightedEmployee !== "--Select--" && netid !== this.props.highlightedEmployee.netid) {
      highlight = { opacity: 0.3 }
      if (event.preferred) {
        highlight = { opacity: 1, color: "white" }
        highlight.backgroundColor = "#228B22";
        name = "Preferred";
      } else if (event.maybe) {
        highlight = { opacity: 1, color: "white" }
        highlight.backgroundColor = "#DAA520";
        name = "Available";
      } else if (!event.published) {
        eventInfo.backgroundColor = "lightGrey";
      } else {
        eventInfo.backgroundColor = "lightSkyBlue";
      }
    } else {
      highlight = { opacity: 1.0 }
      // if the shifts are selected
      const shiftId = event.shiftId;
      const index = this.props.selectedShiftIds.indexOf(shiftId)
      if (shiftId && index !== -1) {
        highlight.backgroundColor = "#CC5500";
      } else if (!event.published && currentView === "timeGridWeek") {
        eventInfo.backgroundColor = "grey";
      }
    }
    if (this.props.colorCodes) {
      divStyle = {
        color: this.props.colorCodes[event.location]
      }
    }
    const displayNote = eventInfo.view.type !== "timeGridWeek" && eventInfo.event.extendedProps.note ? `| ${eventInfo.event.extendedProps.note}` : ''
    return (
      <div ref={this.myRef} className="event" style={highlight}>
        <p>
          <FontAwesomeIcon style={divStyle} icon={faCircle} />
          {eventInfo.view.type === "listWeek" ? <span className="bold">{name}</span> :
            <span className="bold lower">{format((eventInfo.event.start), "h:mma")}&#8211;{format((eventInfo.event.end ? eventInfo.event.end : eventInfo.event.start), "h:mma")}</span>}
        </p>
        <p>
          {eventInfo.view.type !== "listWeek" ? `${name} | ` : ''}{event.location} {displayNote}
        </p>
      </div>
    )
  }

  autoMatchShifts = (includeEmployees) => {
    this.setState({ autoGenBtn: "Matching in progress...", showIncludeEmployeeModal: false })
    let self = this;
    axios.put("schedule/autogen/" + this.props.data.scheduleId, includeEmployees).then((response) => {
      self.props.getSchedule(this.props.data.scheduleId);
      self.setState({ autoGenBtn: "Auto Match Shifts" })
    }).catch(function (err) {
      self.props.setFlash(["There was an unexpected error matching your shifts."], false);
      self.props.setState({ autoGenBtn: "Auto Match Shifts" })
      console.log(err)
    });
  }

  updateShifts = (users, openShifts, oneWeekOnly) => {
    let self = this;
    let formData = new FormData();
    let firstDayEpoch = null;
    if (oneWeekOnly === 'true') {
      firstDayEpoch = self.props.calendarRef.current.getApi().getDate().getTime()
    }
    formData.append("users", JSON.stringify(users));
    formData.append('openShifts', openShifts);
    formData.append('firstDayEpoch', firstDayEpoch);
    axios.post(`schedule/get_shifts/${this.props.data.scheduleId}`,
      formData,
      { headers: { 'content-type': 'application/form-data' } }
    ).then((response) => {
      const calendarApi = this.props.calendarRef?.current?.getApi(); // Get FullCalendar API
      const events = calendarApi.getEvents();
      events.forEach(getEvent => {
        response.data.forEach(e => {
          if (getEvent.extendedProps.shiftId === e.shiftId) {
            getEvent.setExtendedProp('selected', true);
            let extended = getEvent.extendedProps;
            let formatEvent = {
              edit: 'Save',
              start_time: getEvent.start,
              end_time: getEvent.end,
              location: extended.location,
              shiftId: extended.shiftId,
              status: extended.status,
              employee: extended.employee,
              group: extended.group,
              note: extended.note,
            }
            let selectedShiftIds = this.props.selectedShiftIds;
            let selectedShifts = this.props.selectedShifts;
            let findIndex = selectedShiftIds.indexOf(formatEvent.shiftId);
            if (findIndex === -1) {
              selectedShiftIds.push(formatEvent.shiftId);
              selectedShifts.push(formatEvent);
            }
            this.props.setselectedShiftIds(selectedShiftIds, selectedShifts);
          }
        })
      });
      this.setState({ selecting: false })
    }).catch(function (err) {
      console.log(err)
    });
  }

  handleDelete = () => {
    const self = this;
    axios.put("/shifts/bulkDelete/", { shifts: this.props.selectedShiftIds })
      .then((response) => {
        self.props.getSchedule(this.props.data.scheduleId);
        self.props.resetState();
        self.props.removeAllEventHighlight();
        self.props.setFlash(null, ['Your shifts have been deleted!']);
      }).catch(function (err) {
        console.log(err)
      });
  }

  handlePublish = () => {
    this.props.publishShifts();
  }

  handleUnPublish = () => {
    this.props.unPublishShifts();
  }

  //reset the states
  resetState = () => {
    this.setState({
      start_time: null,
      end_time: null,
      days_of_week: null,
      edit: 'Add',
      employee: null,
      note: ''
    })
  }

  handleKeyDown = (event) => {
    if (event.metaKey) {
      console.log('Command (⌘) key is being held down.');
      this.setState({ multi: true })
    }
    if (event.ctrlKey) {
      console.log('Control (Ctrl) key is being held down.');
      this.setState({ multi: true })
    }
  };

  handleKeyUp = (event) => {
    if (!event.metaKey) {
      console.log('Command (⌘) key is being held released.');
      this.setState({ multi: false })
    }
    if (!event.ctrlKey) {
      console.log('Control (Ctrl) key is being held released.');
      this.setState({ multi: false })
    }
  };

  showLegend = () => {
    return <div className='legend mt-4'>
      <div className='flex-holder'>
        <div>
          <h4>Legend</h4>
        </div>
        <div>
          <div className='square grey'></div>
          <p>Draft shift</p>
        </div>
        <div>
          <div className='square blue'></div>
          <p>Published shift</p>
        </div>
        <div>
          <div className='square orange'></div>
          <p>Selected shift</p>
        </div>
        <div>
          <div className='square green'></div>
          <p>Preferred worktime</p>
        </div>
        <div>
          <div className='square yellow'></div>
          <p>Available worktime</p>
        </div>
      </div>
    </div>
  }

  render() {
    return <div className="column is-9">
      {this.drawCalendar()}
      {this.state.showModel ?
        <ShiftsModal
          type={this.state.showModel}
          closeModal={this.closeModal}
          updateShifts={this.updateShifts}
          users={this.props.data.users}
          startWeek={this.props.calendarRef?.current?.getApi().view.activeStart}
          endWeek={addDays(this.props.calendarRef?.current?.getApi().view.activeEnd, -1)}
        /> : null}
      {this.state.showIncludeEmployeeModal ?
        <IncludeEmployeeModal
          closeModal={this.closeIncludeEmployeeModal}
          autoMatchShifts={this.autoMatchShifts}
          group={this.props.data.group}
        /> : null}
    </div>
  }
}

export default DraftCalendar;