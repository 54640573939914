import React, { Component } from 'react';
import axios from 'axios';

export default class ChangeRole extends Component {

  handleRoleChange = (event) => {
    if (window.confirm(`Are you sure you want to change role to ${event.target.value}?`)) {
      event.preventDefault();
      this.setRole({ netid: this.props.netid, role: event.target.value });
    }
  }

  //set role for a person
  setRole = (role) => {
    axios.put("users/updateUserRole", role)
      .then(() => {
        this.props.updateFlash({ success: true, error: false })
      })
      .catch((error) => {
        this.props.updateFlash({ success: false, error: [error] })
      });
  }

  render() {
    return <div className="control">
      <div className="select">
        <select name="group" defaultValue={this.props.role} onChange={this.handleRoleChange}>
          <option hidden disabled value="none"> -- select role -- </option>
          <option value="employee">Employee</option>
          <option value="supervisor">Supervisor</option>
          <option value="admin">Admin</option>
        </select>
      </div>
    </div>
  }
}