import React, { Component } from 'react';
import axios from 'axios';
import Table from '../common/table';
import ReactDOM from 'react-dom';
import ChangeRole from './changeRole';

export default class ActAs extends Component {
  constructor(props) {
    super();
    this.state = {
      users: [],
      page: 0,
      columns: [
        { title: "Name", data: 'display_name', defaultContent: '-' },
        { title: "Netid", data: 'netid', defaultContent: '-' },
        { title: "Duid", data: 'duid', defaultContent: '-' },
        { title: "Role", data: {role: 'role', netid: 'netid'}, defaultContent: '-' },
        { title: "Action", data: 'netid', defaultContent: '-' },
      ],
      columnDefs: [
        {
          targets: [3],
          createdCell: (td, cellData) => {
            let changeRole;
            if (cellData) {
              changeRole = <ChangeRole netid={cellData.netid} role={cellData.role} updateFlash={this.props.updateFlash}/>
            }
            ReactDOM.render(changeRole, td)
          },
        },
        {
          targets: [4],
          createdCell: (td, cellData) => {
            let actAsBtn;
            if (cellData) {
              actAsBtn = <form action={`${process.env.REACT_APP_EXPRESS_HOST}/admin/actAs`} method="post" >
                <div className="field">
                  <div className="control">
                    <input className="hide" name="netid" value={cellData} readOnly />
                    <button className="button is-info" type="submit">Act As</button>
                  </div>
                </div>
              </form>
            }
            ReactDOM.render(actAsBtn, td)
          },
        },
      ]
    }
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers = () => {
    axios.get("users/all").then((response) => {
      // console.log(response.data);
      this.setState({ users: response.data });
    }).catch(function (err) {
      console.log(err)
    });
  }

  actAsForm = () => {
    let showActAs;
    let endActAs;
    if (localStorage.getItem('actAs') !== "undefined") {
      showActAs = <label className="label">Acting As: {localStorage.getItem("displayName")} {localStorage.getItem("netid")}</label>
      endActAs = <form action={`${process.env.REACT_APP_EXPRESS_HOST}/saml/logout/actAs`} method="get">
        <span className="control">
          <input className="button is-info is-light " type="submit" value='End Act As' />
        </span>
      </form>
    }

    return <div className="card">
      <div className="card-content">
        <h3 className="label">User Table </h3>
        {showActAs}
        <div className="field">
          <div className="control">
            {endActAs}
          </div>
        </div>
        <Table data={this.state.users} columns={this.state.columns} columnDefs={this.state.columnDefs} />
      </div>
    </div>
  }
  render() {
    return this.actAsForm()
  }
}