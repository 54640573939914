import React, { Component } from "react";

export default class TextReminder extends Component {

    state = {
        weeklyReminder: {}, dailyReminder: {}, hourlyReminder: {}, timecardReminder: {}
    }

    componentDidMount() {
        this.setState({
            weeklyReminder: this.props.weeklyReminder, dailyReminder: this.props.dailyReminder,
            hourlyReminder: this.props.hourlyReminder, timecardReminder: this.props.timecardReminder
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.weeklyReminder !== this.props.weeklyReminder || prevProps.dailyReminder !== this.props.dailyReminder || prevProps.hourlyReminder !== this.props.hourlyReminder || prevProps.timecardReminder !== this.props.timecardReminder) {
            this.setState({
                weeklyReminder: this.props.weeklyReminder, dailyReminder: this.props.dailyReminder,
                hourlyReminder: this.props.hourlyReminder, timecardReminder: this.props.timecardReminder
            });
        }
    }

    setReminder = (event, type) => {
        event.preventDefault();
        let target = event.target;
        if (type === "weeklyReminder") {
            if (window.confirm(`Do you want to set a ${type} on ${target.day.value === '1' ? "Monday" : "Sunday"} at ${target.time.value}?`)) {
                let weeklyReminder = {
                    day: target.day.value,
                    time: target.time.value
                }
                // console.log(weeklyReminder);
                this.setState({ [type]: weeklyReminder });
                this.props.updateGroup({ [type]: weeklyReminder });
            }
        } else if (type === "dailyReminder") {
            if (window.confirm(`Do you want to set a ${type} at ${target.time.value}?`)) {
                let dailyReminder = {
                    time: target.time.value
                }
                // console.log(dailyReminder);
                this.setState({ [type]: dailyReminder });
                this.props.updateGroup({ [type]: dailyReminder });
            }
        } else if (type === "hourlyReminder") {
            if (window.confirm(`Do you want to set a ${type} ${target.minutes.value} mins before each shift starts?`)) {
                let hourlyReminder = {
                    minutes: target.minutes.value
                }
                // console.log(hourlyReminder);
                this.setState({ [type]: hourlyReminder });
                this.props.updateGroup({ [type]: hourlyReminder });
            }
        }
    }

    endReminder = (type) => {
        if (window.confirm(`Do you want to end ${type}?`)) {
            this.setState({ [type]: {} });
            this.props.updateGroup({ [type]: {} });
        }
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const id = e.target.id;
        let newId = this.state[id]
        newId[name] = value;
        this.setState({ id: newId });
    }

    textReminder = () => {
        return <>
            <h2>Text Reminders</h2>
            <p>Set up reminders for shifts and timecards. Note that only students
                who have added their phone number to their profile will receive
                those reminders.
            </p>
            <div className="group-border-box">
                <h3>Shifts Reminders Schedule</h3>
                <p>Customize when students will receive a text reminder for their
                    upcoming shifts. You can set up weekly, daily and chosen number
                    of minutes before the most upcoming shift as well.
                </p>
                <form onSubmit={(e) => this.setReminder(e, "weeklyReminder")}>
                    <h4>Weekly:</h4>
                    <div className="field is-grouped">

                        <div className="control">
                            <label className="label">Day:</label>
                            <div className="select">
                                <select name="day" id="weeklyReminder" value={this.state.weeklyReminder.day} onChange={this.handleChange}>
                                    <option value="none" hidden></option>
                                    <option value={'7'}>Sunday</option>
                                    <option value={'1'}>Monday</option>
                                </select>
                            </div>
                        </div>
                        <div className="control">
                            <label className="label">Time:</label>
                            <input name="time" className="input" id="weeklyReminder" type="time" value={this.state.weeklyReminder.time || ''} onChange={this.handleChange} />
                        </div>
                        <div className="control">
                            <button className="button is-info" type="submit" disabled={this.props.waitOnQueue}>Set Reminder</button>
                        </div>
                        {this.state.weeklyReminder.time ? <EndReminderButton type="weeklyReminder" endReminder={this.endReminder} disabled={this.props.waitOnQueue} /> : ''}
                    </div>
                </form>
                <form onSubmit={(e) => this.setReminder(e, "dailyReminder")}>
                    <h4>Daily:</h4>
                    <div className="field is-grouped">

                        <div className="control">
                            <label className="label">Time:</label>
                            <input name="time" className="input" type="time" id="dailyReminder" value={this.state.dailyReminder.time || ''} onChange={this.handleChange} />
                        </div>
                        <div className="control">
                            <button className="button is-info" type="submit" disabled={this.props.waitOnQueue}>Set Reminder</button>
                        </div>
                        {this.state.dailyReminder.time ? <EndReminderButton type="dailyReminder" endReminder={this.endReminder} disabled={this.props.waitOnQueue} /> : ''}
                    </div>
                </form>
                <form onSubmit={(e) => this.setReminder(e, "hourlyReminder")}>
                    <h4>Before Single Shift:</h4>
                    <div className="field is-grouped">

                        <div className="control">
                            <label className="label">Minutes Before shift:</label>
                            <input name="minutes" className="input" type="number" id="hourlyReminder" min="0" value={this.state.hourlyReminder.minutes || ''} onChange={this.handleChange} />
                        </div>
                        <div className="control">
                            <button className="button is-info" type="submit" disabled={this.props.waitOnQueue}>Set Reminder</button>
                        </div>
                        {this.state.hourlyReminder.minutes ? <EndReminderButton type="hourlyReminder" endReminder={this.endReminder} disabled={this.props.waitOnQueue} /> : ''}
                    </div>
                </form>
            </div>
        </>
    }

    setTimecardReminder = (event) => {
        event.preventDefault();
        let target = event.target;
        if (window.confirm(`Do you want to set a timecard reminder on ${target.day.value === '1' ? "Monday" : "Sunday"} at ${target.time.value}?`)) {
            let timecardReminder = {
                day: target.day.value,
                time: target.time.value,
            }
            // console.log(weeklyReminder);
            this.setState({ timecardReminder: timecardReminder });
            this.props.updateGroup({ timecardReminder: timecardReminder });
        }
    }

    timecardReminder = () => {
        return <div className="group-border-box">
            <h3>Timecard Reminders Schedule</h3>
            <p>Customize when students will receive a text reminder to submit their
                timecard.</p>
            <form onSubmit={this.setTimecardReminder}>
                <h4>Bi-Weekly:</h4>
                <div className="field is-grouped">

                    <div className="control">
                        <label className="label">Day:</label>
                        <div className="select">
                            <select name="day" id="timecardReminder" value={this.state.timecardReminder.day} onChange={this.handleChange}>
                                <option value="none" hidden></option>
                                <option value={'6'}>Saturday</option>
                                <option value={'7'}>Sunday</option>
                                <option value={'1'}>Monday</option>
                            </select>
                        </div>
                    </div>
                    <div className="control">
                        <label className="label">Time:</label>
                        <input name="time" className="input" id="timecardReminder" type="time" value={this.state.timecardReminder.time || ''} onChange={this.handleChange} />
                    </div>
                    <div className="control">
                        <button className="button is-info" type="submit" disabled={this.props.waitOnQueue}>Set Reminder</button>
                    </div>
                    {this.state.timecardReminder.time ? <EndReminderButton type="timecardReminder" endReminder={this.endReminder} disabled={this.props.waitOnQueue} /> : ''}
                </div>
            </form>
        </div>
    }

    render() {
        return <>
            {this.textReminder()}
            {this.timecardReminder()}
        </>
    }
}

function EndReminderButton(props) {
    return (
        <div className="control">
            <button className="button is-info is-light" type="button" onClick={() => props.endReminder(props.type)} disabled={props.disabled}>Unset Reminder</button>
        </div>
    )
}