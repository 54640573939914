import React, { Component } from "react";
import axios from 'axios';
import Flash from '../common/flash';
import "./groupIndex.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import Modal from "./modal";
import TextReminder from "./textReminder";
import HoverInfo from "../common/hoverInfo";

export default class GroupIndex extends Component {

    state = {
        ipGroup: false, locations: [], ips: [], modalType: false,
        weeklyReminder: {}, dailyReminder: {}, hourlyReminder: {},
        timecardReminder: {}, waitOnQueue: false, requireCover: false
    }

    componentDidMount() {
        let group = localStorage.getItem('group').split(",")[0];
        this.setGroupData(group);
        this.setState({ ipGroup: group })
    }

    //get group data
    getGroupData = (group) => {
        return axios.get("workGroups/find/" + group);
    }

    setGroupData = async (value) => {
        let response = await this.getGroupData(value);
        if (response.status !== 200) {
            console.log("could not find group");
        } else if (response.data) {
            let data = response.data;
            // console.log(data);
            let newIps = [];
            if (data.ips) {
                newIps = data.ips;
            } else if (data.ipRange) {
                newIps.push({ ipFrom: data.ipFrom, ipTo: data.ipTo });
            }
            this.setState({
                grouperPath: data.grouperPath, teamsUrl: data.teamsUrl,
                requireCover: data.requireCover, locations: data.locations,
                ips: newIps, maxShiftHours: data.maxShiftHours,
                weeklyReminder: data.weeklyReminder || {}, dailyReminder: data.dailyReminder || {},
                hourlyReminder: data.hourlyReminder || {}, timecardReminder: data.timecardReminder || {}
            });
        }
    }

    //get group option from the local storage
    groupOptions = () => {
        var options = []
        let groups = localStorage.getItem('group').split(",");
        groups.forEach((group) =>
            options.push(
                <option key={group} className="capitalize-me" value={group}>{group}</option>
            )
        )
        return options
    }

    // generic change handler
    changeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let id = event.target.id;
        if (name === "ipGroup") {
            this.setGroupData(value);
            this.setState({ [name]: value });
        } else if (name === "ipFrom" || name === "ipTo") {
            let newIPs = this.state.ips;
            if (!newIPs[id]) { newIPs[id] = {} }
            newIPs[id][name] = value;
            this.setState({ ips: newIPs });
        } else if (name === "locations") {
            let newLocations = this.state.locations;
            newLocations[id] = value;
            this.setState({ locations: newLocations });
        } else {
            this.setState({ [name]: value });
        }
    }

    groupSelect = () => {
        return <div className="group-section">
            <h2>Group Selection</h2>
            <p>Select the group you would like to manage:</p>
            <div className="field">
                <div className="control">
                    <div className="select">
                        <select value={this.state.ipGroup} name="ipGroup" onChange={this.changeHandler}>
                            {this.groupOptions()}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    }

    setIPRange = () => {
        if (this.state.ipGroup) {
            let exisitingIps = [];
            if (this.state.ips.length > 0) {
                this.state.ips.map((ip, index) => exisitingIps.push(this.eachIPRange(ip, index)));
            }
            exisitingIps.push(this.eachIPRange({}, exisitingIps.length));
            return <div className="group-border-box">
                <h3>Clock In/Out Locations: IP Ranges</h3>
                <p>Once an IP range is set up, students can only clock in and out when in
                    the vicinity of said IP. You can set up more than one IP range.<br />
                    To find the IP range in your building check for specific computers IP addresses
                    or the Duke Network IP address.</p>
                <div className="field">
                    {exisitingIps}
                </div>
            </div>
        }
    }

    endIP = (index) => {
        let newIPs = this.state.ips;
        newIPs.splice(index, 1);
        this.setState({ ips: newIPs });
        this.updateGroup({ ips: newIPs });
    }

    eachIPRange = (ip, index) => {
        let cancelIPRange;
        if (ip.ipFrom || ip.ipTo) {
            cancelIPRange =
                <div className="control">
                    <button className="button" type="button" onClick={() => this.endIP(index)}>End Range</button>
                </div>
        }
        return <div className="field is-grouped" key={index}>
            <div className="control">
                <label className="label">From:</label>
                <input className="input ipaddress" type="text" name="ipFrom" placeholder="1.1.1.1" id={index} value={ip.ipFrom ? ip.ipFrom : ""} onChange={this.changeHandler} />
            </div>
            <div className="control">
                <label className="label">To:</label>
                <input className="input ipaddress" type="text" name="ipTo" placeholder="2.2.2.2" id={index} value={ip.ipTo ? ip.ipTo : ""} onChange={this.changeHandler} />
            </div>
            <div className="control">
                <button className="button is-info" type="button" onClick={() => this.updateGroup({ ips: this.state.ips })}>Set Range</button>
            </div>
            {cancelIPRange}
            <br />
        </div>
    }

    setGrouperPath = () => {
        let groupPathLabel = [];
        groupPathLabel.push(<p key="label" style={{ display: 'inline-block' }}>Grouper Path:</p>);
        groupPathLabel.push(<HoverInfo key="info" message="Input the Grouper Path from the Grouper app. Don't forget to click Grouper Sync in the Grouper app. Remember that you can edit your group on grouper.oit.duke.edu" />);

        if (this.state.ipGroup) {
            return <div className="group-edit-section">
                <div className='tooltip-form'>
                    <label>{groupPathLabel}</label>
                </div>
                <div className="field is-grouped">
                    <div className="control">
                        <input className="input long-input" type="text" name="grouperPath" value={this.state.grouperPath ? this.state.grouperPath : ""} onChange={this.changeHandler} />
                    </div>
                    <div className="control">
                        <button className="button is-info" type="button" onClick={() => this.updateGroup({ grouperPath: this.state.grouperPath })}>Set Path</button>
                    </div>
                </div>
            </div>
        }
    }

    setTeamsURL = () => {
        let groupUrlLabel = [];
        groupUrlLabel.push(<p key="label" style={{ display: 'inline-block' }}>Teams URL:</p>);
        groupUrlLabel.push(<HoverInfo key="info" message="The Teams Webhook URL allows you to send a Teams notification for clock-in and clock-outs.
        On Teams, go to the channel setting and add a connector, an Incoming Webhook and configure a new webhook. 
        Copy that new webhook link into shiftr and be ready to receive notifications!" />);

        if (this.state.ipGroup) {
            return <div className="group-edit-section">
                <div className='tooltip-form'>
                    <label>{groupUrlLabel}</label>
                </div>
                <div className="field is-grouped">
                    <div className="control">
                        <input className="input long-input" type="text" name="teamsUrl" value={this.state.teamsUrl ? this.state.teamsUrl : ""} onChange={this.changeHandler} />
                    </div>
                    <div className="control">
                        <button className="button is-info" type="button" onClick={() => this.updateGroup({ teamsUrl: this.state.teamsUrl })}>Set URL</button>
                    </div>
                </div>
            </div>
        }
    }

    setMaxHours = () => {
        if (this.state.ipGroup) {
            return <div className="group-border-box">
                <h3>Hours Restrictions</h3>
                <p>When setting hours restrictions, students will be limited on the amount of shifts they can claim from the open shift pool so as to not go over that time.
                    Hours restrictions do not affect automatch, clock in/out or self report time.<br />
                    To unset a limit, reset the hours restrictions to 0.</p>
                <div className="field is-grouped">
                    <div className="control">
                        <label className="label">Hours:</label>
                        <input className="input hours-restrictions" type="number" min={0} name="maxShiftHours" value={this.state.maxShiftHours ? this.state.maxShiftHours : ''} onChange={this.changeHandler} />
                    </div>
                    <div className="control">
                        <button className="button is-info" type="button" onClick={() => this.updateGroup({ maxShiftHours: this.state.maxShiftHours })}>Set Hours</button>
                    </div>
                </div>
            </div>
        }
    }

    setModalType = (type) => {
        this.setState({ modalType: type });
    }

    logAction = (reason) => {
        axios.get("/users/log/" + reason)
            .then((response) => {
                // console.log(response.data);
            }).catch((error) => {
                console.log(error)
            });
    }

    requireShiftCoverage = () => {
        let groupShiftLabel = [];
        groupShiftLabel.push(<p key="label" style={{ display: 'inline-block' }}>Shift Coverage:</p>);
        groupShiftLabel.push(<HoverInfo key="info" message="When selected, employees who offer up their shifts will still be responsible for that shift until it is either released by a supervisor or covered by another employee." />);

        if (this.state.ipGroup) {
            return <div className="group-edit-section">
                <div className='tooltip-form'>
                    <label>{groupShiftLabel}</label>
                </div>
                <div className="field is-grouped">
                    <div className="control">
                        <label className="checkbox">
                            <input type="checkbox" name="requireCover" checked={this.state.requireCover} onChange={(e) => this.setState({ requireCover: e.target.checked })} />
                            Require shift cover
                        </label>
                    </div>
                    <div className="control">
                        <button className="button is-info" type="button" onClick={() => this.updateGroup({ requireCover: this.state.requireCover })}>Set Coverage</button>
                    </div>
                </div>
            </div>
        }
    }

    addNewLocation = () => {
        this.setState(prevState => ({ locations: prevState.locations.concat('') }))
    }

    //delete the last location input field
    removeLocationField = () => {
        this.state.locations.pop()
        this.setState(prevState => ({
            myArray: [prevState.locations]
        }))
    }

    setGroupLocation = () => {
        if (this.state.ipGroup) {
            return <div className="field group-edit-section">
                <label className="label">Group Locations:</label>
                <div className='location-buttons'>
                    <FontAwesomeIcon className="clickable" icon={faPlusCircle} onClick={this.addNewLocation} />
                    <FontAwesomeIcon className="clickable" icon={faMinusCircle} onClick={this.removeLocationField} />
                    <span>Add/Remove a work location</span>
                </div>
                <div className="field is-grouped">
                    <div className="control">
                        {this.state.locations.map((location, index) => <input className="input short-input" key={index} type="text" name="locations" id={index} value={location} onChange={this.changeHandler} />)}
                    </div>
                    <div className="control">

                        <button className="button is-info" type="button" onClick={() => this.updateGroup({ locations: this.state.locations })}>Set Locations</button>
                    </div>
                </div>
            </div>
        }
    }

    updateMessageSchedule = (body) => {
        let group = this.state.ipGroup;
        axios.put(`workGroups/update/${group}`, body).then(response => {
            this.setState({ success: [`Your group has been updated ${body.name}.`], error: false });
            this.setState({ waitOnQueue: true });
            axios.get(`messageScheduler/clearAndSetQueue/${group}`).then(response => {
                console.log("response", response);
                this.setState({ waitOnQueue: false });
            }).catch((error) => {
                console.log(error)
            });
        }).catch((error) => {
            console.log(error)
        });
    }

    updateGroup = (body) => {
        console.log("body is", body)
        if (body.ips) {
            body.ipFrom = null;
            body.ipTo = null;
            body.ipRange = null;
        }
        if (body.hasOwnProperty("requireCover")) {
            this.logAction(`requireCover changed to ${body.requireCover}`)
        }
        axios.put(`workGroups/update/${this.state.ipGroup}`, body).then(response => {
            this.setState({ success: [`Your group has been updated ${body.name}.`], error: false });
        }).catch((error) => {
            console.log(error)
        });
    }

    setFlash = (error, success) => {
        this.setState({ error: error, success: success });
    };

    //show error/success message
    flash = () => {
        let ret = [];

        if (this.state.success) {
            ret.push(<Flash key="success" type="success" title="Success" messages={["Your edit has been saved!"]} closeFlash={() => this.setState({ success: false })} />);
        }

        if (this.state.error) {
            let errTitle = "Incomplete Form";
            let errMessage = this.state.error.toString();

            //check for systen error
            if (errMessage.slice(0, 5) === "SYS!:") {
                errTitle = "System Error";
                errMessage = errMessage.slice(5, errMessage.length);
            }

            ret.push(
                <Flash key="error" type="error" title={errTitle} messages={[errMessage]} closeFlash={() => this.setState({ error: false })} />
            );

        }

        return ret;
    };

    closeModal = () => {
        this.setState({ modalType: false })
    }

    render() {
        return <div className="container">
            {this.flash()}
            <Modal type={this.state.modalType} clockModal={this.clockModal} />
            <div className="manage-group-wrapper">
                <h1>Group Management</h1>
                {this.groupSelect()}
                <div className="main">
                    <div className="group-section">
                        <TextReminder weeklyReminder={this.state.weeklyReminder} dailyReminder={this.state.dailyReminder}
                            hourlyReminder={this.state.hourlyReminder} timecardReminder={this.state.timecardReminder}
                            updateGroup={this.updateMessageSchedule}
                            waitOnQueue={this.state.waitOnQueue} setModalType={this.setModalType} />
                    </div>
                </div>
                <div className="group-section group-edit">
                    <h2>Group Editing</h2>
                    <div className="group-border-box">
                        {this.setGrouperPath()}
                        {this.setTeamsURL()}
                        {this.requireShiftCoverage()}
                        {this.setGroupLocation()}
                    </div>
                    <h2>Group Restrictions</h2>
                    {this.setIPRange()}
                    {this.setMaxHours()}
                </div>
            </div>
        </div>
    }
}